import React from 'react';

interface Props {
  remainingCodes: number;
}

const InviteCodeNotSelected: React.FC<Props> = ({ remainingCodes }) => {
  const CSSBlock = 'invite-code-view';

  const limitExceeded = () => (
    <>
      <div className={`${CSSBlock}__title`}>You&apos;ve reached your limit</div>
      <div className={`${CSSBlock}__message`}>You&apos;ve used all your invite codes.</div>
      <div className={`${CSSBlock}__message`}>
        If one of your invitees hasn&apos;t redeemed their code, you can still revoke it and invite another person.
      </div>
    </>
  );
  const limitNotExceeded = () => (
    <>
      <div className={`${CSSBlock}__title`}>
        You&apos;ve got {remainingCodes} code{remainingCodes > 1 ? 's' : ''} remaining
      </div>
      <div className={`${CSSBlock}__message`}>
        You&apos;re allowed to invite a maximum of three people to the early access of Demmi.
      </div>
    </>
  );

  return <div className={`${CSSBlock}__info`}>{remainingCodes <= 0 ? limitExceeded() : limitNotExceeded()}</div>;
};

export default InviteCodeNotSelected;
