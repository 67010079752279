import { User } from 'firebase/auth';
import React from 'react';

import ScreenWrapper from './ScreenWrapper';

interface Screen01Props {
  user: User;
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen00: React.FC<Screen01Props> = ({ user, isMounted, mountedStyle, unmountedStyle }) => {
  const CSSBlock = 'welcome-section';

  return (
    <ScreenWrapper
      cardIndex={0}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={
        <>
          <div className={`${CSSBlock}__name`}>Hey {user.displayName?.split(' ')[0]}!</div>
          <div className={`${CSSBlock}__welcome`}>
            Welcome to <span>Demmi</span>
          </div>
        </>
      }>
      <div className={`${CSSBlock}__description`}>Let&apos;s help you get started.</div>
    </ScreenWrapper>
  );
};

export default Screen00;
