import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToInviteCode = (doc: QueryDocumentSnapshot<DemmiFS.FSInviteCode>): DemmiFS.InviteCode => {
  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSInviteCode),
  };
};
