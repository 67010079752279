import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LogoWhite from '@assets/demmi-logo-white.svg';
import Logo from '@assets/demmi-logo.svg';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getViewFromPath } from '@core/services/routingService';
import { selectIsLoading } from '@core/store/appSlice/appSlice';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { navigateTransitioned } from '@helpers/app.helper';

import LoadingGlobal from '../loadingIntro/LoadingGlobal';
import PortalSidebar from './PortalSidebar';
import { DemmiHooks } from '@subhanhabib/demmilib';

interface Props extends PropsWithChildren {
  isMounted: boolean;
  shouldRender: boolean;
}

const PortalWrapper: React.FC<Props> = ({ isMounted, shouldRender, children }) => {
  const CSSBlock = 'portal-wrapper';
  const mountedStyle = { animation: 'inAnimation 400ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Global loading indicator
  const isLoading = useAppSelector(selectIsLoading);
  // const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  // const shouldRenderGlobalLoading = useDelayUnmount(isGlobalLoadingMounted, 400);
  // useEffect(() => {
  //   setIsGlobalLoadingMounted(isLoading);
  // }, [isLoading]);

  // Page loading indicator
  // const isPageLoading = useAppSelector(selectIsLoading);
  const [isPageLoadingMounted, setIsPageLoadingMounted] = useState(false);
  const shouldRenderPageLoading = DemmiHooks.useDelayUnmount(isPageLoadingMounted, 400);
  useEffect(() => {
    setIsPageLoadingMounted(isLoading);
  }, [isLoading]);

  return (
    <div className={`${CSSBlock} ${isMenuVisible ? `${CSSBlock}--menu-visible` : ''}`}>
      <PortalSidebar
        navigateTransitioned={to => {
          navigateTransitioned({ dispatch, navigate, to });
          setIsMenuVisible(false);
        }}
      />
      <div className={`${CSSBlock}__content-wrapper`} onClick={() => setIsMenuVisible(false)}>
        <div
          className={`${CSSBlock}__content ${CSSBlock}--${getViewFromPath(pathname)}`}
          style={isMounted ? mountedStyle : unmountedStyle}>
          {shouldRender && children}
        </div>
        {shouldRenderPageLoading && <LoadingGlobal isMounted={isPageLoadingMounted} />}
      </div>
      <div className={`${CSSBlock}__nav-bar`}>
        <div className={`${CSSBlock}__menu-toggle-button`}>
          <DemmiIconButton invert className="fa-bars" faIcon="fa-bars" onClick={() => setIsMenuVisible(true)} />
          <DemmiIconButton
            invert
            className="fa-arrow-left"
            faIcon="fa-arrow-left"
            onClick={() => setIsMenuVisible(false)}
          />
        </div>
        <div className={`${CSSBlock}__nav-bar-logo`}>
          <img src={Logo} className={`${CSSBlock}__logo-colour`} />
          <img src={LogoWhite} className={`${CSSBlock}__logo-white`} />
        </div>
        <div className={`${CSSBlock}__nav-bar-empty`} />
      </div>
      <div id="modal-container" />
      {/* {shouldRenderGlobalLoading && <LoadingGlobal isMounted={isGlobalLoadingMounted} />} */}
    </div>
  );
};

export default PortalWrapper;
