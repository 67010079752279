import React from 'react';

import LogoWhite from '@assets/demmi-logo-white.svg';
import Logo from '@assets/demmi-logo.svg';

const WelcomeHeader: React.FC = () => {
  const CSSBlock = 'welcome';

  return (
    <div className={`${CSSBlock}__header`}>
      <div className={`${CSSBlock}__logo`}>
        <img src={Logo} className={`${CSSBlock}__logo-colour`} />
        <img src={LogoWhite} className={`${CSSBlock}__logo-white`} />
      </div>
    </div>
  );
};

export default WelcomeHeader;
