import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  selectedCode: DemmiFS.InviteCode | undefined;
}

const InviteCodesSidebarActiveItemMobileView: React.FC<Props> = ({ selectedCode, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}__sidebar-active-item`;

  return selectedCode ? (
    <div className={`${CSSBlock}`}>
      <span className={`${CSSBlock}__code`}>{`${selectedCode.code.substring(0, 3)}-${selectedCode.code.substring(
        3
      )}`}</span>
      <span className={`${CSSBlock}__email`}>{`${selectedCode.email}`}</span>
    </div>
  ) : (
    <></>
  );
};

export default InviteCodesSidebarActiveItemMobileView;
