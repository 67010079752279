import React, { useEffect } from 'react';

const fps = 60;

interface AppThemeProps {
  isLogin: boolean;
}

let themeInterval = 0;

const AppTheme: React.FC<AppThemeProps> = ({ isLogin }) => {
  const animate = () => {
    requestAnimationFrame(() => {
      // if (process.env.IS_PRODUCTION) {
      //   animatePos();
      // }
    });
  };

  useEffect(() => {
    // isLogin
    //   ?
    themeInterval = window.setInterval(animate, 1000 / fps);
    // : clearInterval(themeInterval);
    return () => clearInterval(themeInterval);
  }, [isLogin]);

  useEffect(() => {
    return () => clearInterval(themeInterval);
  }, []);

  return <></>;
};

export default AppTheme;
