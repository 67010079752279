import React, { useEffect } from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import PortalWrapper from '@components/portalWrapper/PortalWrapper';
import AppTheme from '@core/AppTheme';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectGlobalContentMounted, setGlobalContentMounted } from '@core/store/appSlice/appSlice';
import { selectCustomer, selectIsFetchingCustomer } from '@core/store/userSlice/userSlice';
import { useAuth } from '@providers/authProvider';
import { DemmiHelpers, DemmiHooks, Logger } from '@subhanhabib/demmilib';

const ProtectedRoute: React.FC = () => {
  const isMounted = useAppSelector(selectGlobalContentMounted);
  const shouldRender = DemmiHooks.useDelayUnmount(isMounted, 100);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const customer = useAppSelector(selectCustomer);
  const isFetchingCustomer = useAppSelector(selectIsFetchingCustomer);

  useEffect(() => {
    dispatch(setGlobalContentMounted(true));
  }, [pathname]);

  const outlet = useOutlet();
  Logger({ objs: { isFetchingCustomer, user, customer } }, ProtectedRoute);
  return !isFetchingCustomer ? (
    user ? (
      customer ? (
        !DemmiHelpers.Customer.isSetupComplete(customer) ? (
          <Navigate to={routingPaths[RoutingViews.USER_SETUP]} />
        ) : (
          <>
            <AppTheme isLogin={false} />
            <PortalWrapper isMounted={isMounted} shouldRender={shouldRender}>
              {outlet}
            </PortalWrapper>
          </>
        )
      ) : (
        (Logger({ messages: 'NO CUSTOMER', objs: { customer } }, ProtectedRoute), (<Navigate to="/" />))
      )
    ) : (
      (Logger({ messages: 'NO USER', objs: { user } }, ProtectedRoute), (<Navigate to="/" />))
    )
  ) : (
    (Logger({ messages: 'FETCHING CUSTOMER', objs: { isFetchingCustomer } }, ProtectedRoute),
    (<LoadingGlobal isMounted={true} />))
  );
};
export default ProtectedRoute;
