import { Timestamp, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

export const getInviteCode = async (code: string): Promise<DemmiFS.InviteCodeWithRaw | undefined> => {
  Logger({ objs: { code } }, getInviteCode);
  const q = query(FSCollections.InviteCodes, where('code', '==', code));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return;
  return {
    docID: querySnapshot.docs[0].id,
    raw: querySnapshot.docs[0].data(),
    ...(querySnapshot.docs[0].data() as DemmiFS.FSInviteCode),
  };
};

export const isInviteCodeValid = async (code: string, email: string): Promise<boolean> => {
  Logger({ objs: { code, email } }, isInviteCodeValid);
  const q = query(FSCollections.InviteCodes, where('code', '==', code), where('email', '==', email));
  const querySnapshot = await getDocs(q);
  return querySnapshot.size === 1;
};

export const activateInviteCode = async (code: string, email: string): Promise<void> => {
  Logger({ objs: { code, email } }, activateInviteCode);
  const q = query(FSCollections.InviteCodes, where('code', '==', code), where('email', '==', email));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return;

  const docRef = doc(FSCollections.InviteCodes, querySnapshot.docs[0].id);
  return updateDoc(docRef, {
    activated: true,
    activatedAt: Timestamp.fromDate(new Date()),
  });
};
