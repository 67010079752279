import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { DemmiFS } from '@subhanhabib/demmilib';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  customer: DemmiFS.FSUser;
}
const Screen04: React.FC<Props> = ({ isMounted, mountedStyle, unmountedStyle, customer }) => {
  const CSSBlock = 'welcome-section';

  return (
    <ScreenWrapper
      cardIndex={4}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={"I think we've got everything we need"}>
      <div className={`${CSSBlock}__description`}>
        Thanks for the info. If this is all correct, click next and we&apos;ll get your account set up.
      </div>
      <div className={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Title</div>
          <div className={`${CSSBlock}__item-value`}>{customer.title}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>First Name</div>
          <div className={`${CSSBlock}__item-value`}>{customer.firstName}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Last Name</div>
          <div className={`${CSSBlock}__item-value`}>{customer.lastName}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Nickname</div>
          <div className={`${CSSBlock}__item-value`}>{customer.nickname}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Email</div>
          <div className={`${CSSBlock}__item-value`}>{customer.email ?? '-'}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>{formatPhoneNumberIntl(customer.contactNumber)}</div>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default Screen04;
