import { forwardRef } from 'react';
import { DefaultInputComponentProps } from 'react-phone-number-input';

import DemmiInput from './Input';

const DemmiInputPhone = forwardRef<HTMLInputElement, DefaultInputComponentProps>(
  (props: DefaultInputComponentProps, ref) => {
    return <DemmiInput type="text" ref={ref} value={props.value} onChangeValue={props.onChange} />;
  },
);

DemmiInputPhone.displayName = 'DemmiInputPhone';
export default DemmiInputPhone;
