import React, { useState } from 'react';

import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { DemmiButtonSize } from '@demmi-ui/Buttons/buttons.helper';

import { SidebarManagedButton, SidebarProps, isSidebarSelfManaged, isSidebarTitleButton } from './Sidebar';
import SidebarButton from './SidebarButton';

const SidebarMobile: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar-mobile';
  const [collapsed, setCollapsed] = useState(true);
  const { active, title, titleButtons, onClick } = props;

  const isCustomButton = isSidebarSelfManaged(props);
  const buttons = isCustomButton ? props.customButtons : props.buttons;

  const headerView = () => (
    <div className={`${CSSBlock}__header`}>
      <div className={`${CSSBlock}__title`}>
        <div className={`${CSSBlock}__title-empty`} />
        {title}
        {titleButtons && (
          <div className={`${CSSBlock}__title-buttons`}>
            {titleButtons.map((titleButton, i) =>
              isSidebarTitleButton(titleButton) ? (
                <DemmiIconButton
                  key={i}
                  faIcon={titleButton.faIcon}
                  onClick={titleButton.onClick}
                  size={DemmiButtonSize.S}
                />
              ) : (
                titleButton
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );

  const buttonsView = () => (
    <div className={`${CSSBlock}__buttons`}>
      {buttons.map((button, i) =>
        isCustomButton ? (
          [
            ...(i > 0 && props.customButtonsDivider ? [props.customButtonsDivider] : []),
            <SidebarButton
              element={button as JSX.Element}
              active={i === active}
              key={i}
              onClick={() => {
                setCollapsed(true);
                onClick(i);
              }}
            />,
          ]
        ) : (
          <SidebarButton
            text={(button as SidebarManagedButton).text}
            faIcon={(button as SidebarManagedButton).faIcon}
            active={i === active}
            key={i}
            onClick={() => {
              setCollapsed(true);
              onClick(i);
            }}
          />
        ),
      )}
    </div>
  );
  const activeItemView = () =>
    buttons[active] ? (
      <div className={`${CSSBlock}__active-item`}>
        {isCustomButton ? props.activeItemMobileView : (buttons[active] as SidebarManagedButton).id}
      </div>
    ) : (
      <div className={`${CSSBlock}__active-item ${CSSBlock}__active-item--none`}>No item selected</div>
    );

  const footerView = () => (
    <div className={`${CSSBlock}__footer`}>
      <DemmiIconButton
        className="fa-caret-up"
        faIcon={'fa-caret-up'}
        onClick={() => setCollapsed(true)}
        size={DemmiButtonSize.XS}
      />
      <DemmiIconButton
        className="fa-caret-down"
        faIcon={'fa-caret-down'}
        onClick={() => setCollapsed(false)}
        size={DemmiButtonSize.XS}
      />
    </div>
  );

  return (
    <div
      className={`${CSSBlock} ${collapsed ? `${CSSBlock}--collapsed` : ``} ${
        isCustomButton ? `${CSSBlock}--custom-buttons` : ``
      }`}>
      {headerView()}
      {activeItemView()}
      {buttonsView()}
      {footerView()}
    </div>
  );
};

export default SidebarMobile;
