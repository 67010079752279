import * as MenuTypes from './MenuTypes';

interface Props<T> {
  item: MenuTypes.MenuItem<T>;
  onClick: () => void;
}

const MenuItem = <T,>({ item, onClick }: Props<T>): JSX.Element => {
  const CSSBlock = 'demmi-ui-menu-item';
  return (
    <button className={CSSBlock} onClick={onClick} disabled={item.disabled}>
      <span className={`${CSSBlock}__text`}>{item.text}</span>
    </button>
  );
};

export default MenuItem;
