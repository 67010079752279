import { doc, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService';

// ----------
// Common Queries
// ----------

export const orderQuery = (orderDocID: string) => doc(FSCollections.Orders, orderDocID);

export const getOrdersQuery = (customerID: string) =>
  query(
    FSCollections.Orders,
    where(`customerID`, '==', customerID) // only if this vendor exists
  );

export const orderReviewsVendorQuery = (vendorID: string, orderID: string) =>
  query(FSCollections.OrderReviews([vendorID, FSSubCollectionNames.REVIEWS]), where('orderID', '==', orderID));
