import { QueryDocumentSnapshot } from 'firebase/firestore';

import { DemmiFS } from '@subhanhabib/demmilib';

export const parseToOrder = (doc: QueryDocumentSnapshot<DemmiFS.FSOrder>): DemmiFS.Order => {
  const vendors = Object.entries(doc.data()['vendors']).reduce((acc: DemmiFS.FSOrderVendors, [vendorID, vendor]) => {
    const coupon: DemmiFS.FSOrderVendorCoupon | undefined =
      vendor.coupon && vendor.coupon?.type
        ? {
            ...vendor.coupon!,
            type: DemmiFS.CouponHelper.getType(vendor.coupon!.type),
          }
        : undefined;
    const products = vendor.products.map(p => ({
      ...p,
      variantUnit: DemmiFS.UnitsHelper.getWeightUnit(p.variantUnit) ?? p.variantUnit,
    }));
    const updatedVendor: DemmiFS.FSOrderVendor = { ...vendor, coupon, products };
    return { ...acc, [vendorID]: updatedVendor };
  }, {});

  const status = DemmiFS.OrderStatusHelper.getOrderStatus(doc.data().status);

  return {
    docID: doc.id,
    ...(doc.data() as DemmiFS.FSOrder),
    status: status,
    vendors,
    timeline: doc.data().timeline
      ? doc.data().timeline.map(update => ({
          ...update,
          statusUpdate: DemmiFS.OrderStatusHelper.getOrderVendorStatus(update.statusUpdate),
          updatedBy: DemmiFS.OrderTimelineHelper.getUpdatedBy(update.updatedBy),
        }))
      : [],
  };
};
