import { UserCredential } from 'firebase/auth';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DemmiFS } from '@subhanhabib/demmilib';

import { RootState } from '../store';

interface UserState {
  cred: UserCredential | undefined;
  customer: DemmiFS.User | undefined;
  isFetchingCustomer: boolean;
}

const initialState: UserState = {
  cred: undefined,
  customer: undefined,
  isFetchingCustomer: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    clearCred: _ => {},
    storeCred: (_1, _2: PayloadAction<UserCredential>) => {},
    setCustomer: (state, action: PayloadAction<DemmiFS.User | undefined>) => {
      state.customer = action.payload;
    },
    setIsFetchingCustomer: (state, action: PayloadAction<boolean>) => {
      state.isFetchingCustomer = action.payload;
    },
  },
});

export const { clearCred, storeCred, setCustomer, setIsFetchingCustomer } = userSlice.actions;

export const selectUserCred = (state: RootState) => state.user.cred;
export const selectCustomer = (state: RootState) => state.user.customer;
export const selectIsFetchingCustomer = (state: RootState) => state.user.isFetchingCustomer;

export default userSlice.reducer;
