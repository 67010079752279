import { format } from 'date-fns';
import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  selectedOrder: DemmiFS.Order | undefined;
}

const OrderHistorySidebarActiveItemMobileView: React.FC<Props> = ({ selectedOrder, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}__sidebar-active-item`;

  return selectedOrder ? (
    <div className={`${CSSBlock}`}>
      <span className={`${CSSBlock}__heading`}>{`${selectedOrder.orderID}`}</span>
      <span className={`${CSSBlock}__description`}>
        {format(DemmiFS.getDateFromFirestore(selectedOrder!.orderDate)!, 'dd MMM yyyy, HH:mm:ss')}
      </span>
    </div>
  ) : (
    <></>
  );
};

export default OrderHistorySidebarActiveItemMobileView;
