import React, { useRef, useState } from 'react';

import MenuCore, { DemmiMenuCoreRef } from './MenuCore';
import MenuItem from './MenuItem';
import { MenuContent, MenuSection } from './MenuTypes';
import { DemmiHooks } from '@subhanhabib/demmilib';

const CSSBlock = 'demmi-ui-menu';

interface Props<T> {
  content: MenuContent<T>;
  triggerEl: JSX.Element;
  onClick: (id: T) => void;
}

const Menu = <T = string,>({ content, triggerEl, onClick }: Props<T>): JSX.Element => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuCoreRef = useRef<DemmiMenuCoreRef>(null);
  const [triggerBounds, setTriggerBounds] = useState<DOMRect>();

  const gracefulClose = (onClose: () => void) => {
    menuCoreRef.current ? menuCoreRef.current.gracefulClose(onClose) : onClose();
  };

  const { ref, isVisible, setIsVisible } = DemmiHooks.useComponentVisible<HTMLDivElement>(
    false,
    () => new Promise<void>(resolve => gracefulClose(() => resolve())),
  );

  triggerEl = React.cloneElement(triggerEl, {
    onClick: () => {
      isVisible ? gracefulClose(() => setIsVisible(false)) : setIsVisible(true);
      if (triggerRef.current) setTriggerBounds(triggerRef.current.getBoundingClientRect());
    },
    ref: triggerRef,
  });

  const onItemClick = (id: T) => {
    onClick(id);
    gracefulClose(() => setIsVisible(false));
  };

  const contents = (sections: MenuSection<T>[], onClick: (id: T) => void) =>
    sections.map((section, i) => (
      <div
        key={i}
        className={`${CSSBlock}__area ${section.isDark ? `${CSSBlock}__area--dark` : `${CSSBlock}__area--light`} `}>
        {section.heading && <div className={`${CSSBlock}__section-heading`}>{section.heading}</div>}
        <div className={`${CSSBlock}__section-items`}>
          {section.items.map((item, j) => (
            <MenuItem key={j} item={item} onClick={() => onClick(item.item)} />
          ))}
        </div>
      </div>
    ));

  return (
    <div>
      <span style={{ marginLeft: 'auto' }}>{triggerEl}</span>
      {triggerBounds && (
        <MenuCore
          ref={menuCoreRef}
          onExited={() => setIsVisible(false)}
          isOpen={isVisible}
          triggerBounds={triggerBounds}>
          <div ref={ref}>{contents(content.sections, onItemClick)}</div>
        </MenuCore>
      )}
    </div>
  );
};

export default Menu;
