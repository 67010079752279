import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalCreateInviteCode from '@components/modals/CreateInviteCode/ModalCreateInviteCode';
import Page from '@components/page/Page';
import { useAppSelector } from '@core/hooks/appHooks';
import { getInviteCodes } from '@core/services/networkService/inviteCodes/inviteCodes';
import { RouteParams, RoutingViews, getRouteFromPath } from '@core/services/routingService';
import { selectCustomer } from '@core/store/userSlice/userSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, DemmiHelpers, Logger } from '@subhanhabib/demmilib';

import InviteCodeContent from './InviteCodeContent';
import InviteCodeNotSelected from './InviteCodeNotSelected';
import InviteCodesSidebarActiveItemMobileView from './_sidebarActiveItemMobileView';
import InviteCodesSidebarButton from './_sidebarButton';

const InviteCodes: React.FC = () => {
  const CSSBlock = 'invite-codes';

  const customer = useAppSelector(selectCustomer);
  const navigate = useNavigate();
  const { [RouteParams.INVITE_CODE_ID]: codeID } = useParams();

  const [codes, setCodes] = useState<DemmiFS.InviteCode[]>([]);
  const [filteredCodes, setFilteredCodes] = useState<DemmiFS.InviteCode[]>([]);
  const [selectedCode, setSelectedCode] = useState<DemmiFS.InviteCode | undefined>();

  useEffect(() => {
    const order = codes.find(f => f.docID === codeID);
    if (order) {
      setSelectedCode(undefined);
      setTimeout(() => {
        if (order) setSelectedCode(order);
      }, 50);
    }
  }, [codeID, codes]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredCodes(codes), [codes]);

  const onClickCode = (index: number) => {
    const code = codes[index];
    Logger({ objs: { code } }, onClickCode);
    setSelectedCode(undefined);
    navigate(
      getRouteFromPath(RoutingViews.INVITE_CODES_DETAIL, {
        [RouteParams.INVITE_CODE_ID]: code.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (code) setSelectedCode(code);
    }, 50);
  };

  const filterCodes = (filter: string) => {
    const filtered = codes.reduce((acc: DemmiFS.InviteCode[], cur: DemmiFS.InviteCode) => {
      if (filter && !cur.code.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredCodes(filtered);
  };

  const onClickRefresh = () => {
    if (customer) demmiRequest(getInviteCodes(customer.docID)).then(f => setCodes(f));
    setSelectedCode(undefined);
  };

  const sidebarButtons = () =>
    filteredCodes.map(code => <InviteCodesSidebarButton key={code.docID} CSSBlock={CSSBlock} code={code} />);
  const sidebarActiveItemMobileView = () => (
    <InviteCodesSidebarActiveItemMobileView CSSBlock={CSSBlock} selectedCode={selectedCode} />
  );

  const sidebarTitleButtons = [
    { faIcon: 'fa-rotate-right', onClick: onClickRefresh },
    ...(DemmiHelpers.InviteCodes.countRemaining(codes) > 0
      ? [
          <ModalCreateInviteCode
            customerDocID={customer?.docID}
            onClose={() => {}}
            onConfirm={onClickRefresh}
            key={0}
          />,
        ]
      : []),
  ];

  return (
    <Page
      sidebarFixed={true}
      sidebarTitleButtons={sidebarTitleButtons}
      sidebarButtons={sidebarButtons()}
      sidebarButtonDivider={<div className={`${CSSBlock}__sidebar-button-divider`} />}
      sidebarActiveItemMobileView={sidebarActiveItemMobileView()}
      sidebarActive={codes.findIndex(f => selectedCode?.docID === f.docID)}
      sidebarTitle="Your Codes"
      sidebarOnClick={onClickCode}
      sidebarOnSearch={filterCodes}
      sidebarSearchPlaceholder="Filter Codes">
      {selectedCode ? (
        <InviteCodeContent code={selectedCode} refreshCode={onClickRefresh} />
      ) : (
        <InviteCodeNotSelected remainingCodes={DemmiHelpers.InviteCodes.countRemaining(codes)} />
      )}
    </Page>
  );
};

export default InviteCodes;
