import React from 'react';

import { useAuth } from '@providers/authProvider';

const LoadingAuth: React.FC = () => {
  const CSSBlock = 'loading-intro';

  const onLoad = () => {
    const { user } = useAuth();
    if (!user) return;
  };

  onLoad();

  // TODO: REMOVE
  return <div className={`${CSSBlock} ${CSSBlock}--auth `}>Deprecated</div>;
};

export default LoadingAuth;
