import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppDispatch } from '@core/hooks/appHooks';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import { setCustomer } from '@core/store/userSlice/userSlice';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  customer: DemmiFS.User;
}

const CustomerAddress: React.FC<Props> = ({ customer }) => {
  const CSSBlock = 'customer-details-block';
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState<DemmiFS.FSUserAddress | undefined>(customer.address);
  const addressDefinitions = DemmiFS.DemmiAddressHelper.getFieldDefinitions(customer.address.countryCode);

  if (!addressDefinitions) return <></>;

  const updateCustomer = async () => {
    if (!address?.address) return;
    setIsLoading(true);
    await FSCustomer.profile.updateHomeAddress(customer.docID, address).then(() => {
      FSCustomer.doc.getByDocID(customer.docID).then(customer => {
        dispatch(setCustomer(customer));
        setIsLoading(false);
      });
    });
  };

  const onSave = async () => {
    await updateCustomer();
    setEditMode(false);
  };

  const onCancel = () => {
    setAddress(customer.address);
    setEditMode(false);
  };

  const updateAddressField = (fieldKey: string, value: string) => {
    if (address) {
      const updatedAddress: DemmiFS.DemmiAddress = {
        ...address,
        address: {
          ...DemmiFS.DemmiAddressHelper.getEmptyDemmiAddress(address.countryCode),
          ...address?.address,
          [fieldKey]: value,
        },
      };
      setAddress(updatedAddress);
    }
  };

  const getMenuContent = (items: string[]) => {
    return {
      sections: [
        {
          items: items.map(c => ({
            id: c.replaceAll(' ', ''),
            text: c,
            item: c,
          })),
        },
      ],
    };
  };
  const editModeInput = (key: string, value: DemmiFS.DemmiAddressFieldDefinition) => (
    <>
      {value.values ? (
        <Menu<string>
          content={getMenuContent(Object.values(value.values))}
          triggerEl={<MenuTriggerButton text={DemmiFS.DemmiAddressHelper.getFieldValue(key, address!) ?? ''} />}
          onClick={val => updateAddressField(key, val)}
        />
      ) : (
        <DemmiInput
          type="text"
          placeholder={value.hint}
          value={DemmiFS.DemmiAddressHelper.getFieldValue(key, address!)}
          maxLength={value.maxLength}
          onChangeValue={val => updateAddressField(key, val)}
          error={value.validate ? !value.validate(DemmiFS.DemmiAddressHelper.getFieldValue(key, address!)) : false}
        />
      )}
      {value.validate && <span className={`${CSSBlock}__item-value-required`}>Required Field *</span>}
    </>
  );

  return (
    <div
      className={`${CSSBlock} ${editMode ? `${CSSBlock}--edit-mode` : ``} ${isLoading ? `${CSSBlock}--loading` : ``}`}>
      <div className={`${CSSBlock}__section-title`}>
        Your Home Address
        <div className={`${CSSBlock}__control-buttons`}>
          {!editMode && <DemmiIconButton faIcon="fa-pencil" onClick={() => setEditMode(!editMode)} />}
          {editMode && (
            <>
              <DemmiIconButton faIcon="fa-cancel" onClick={() => onCancel()} />
              <DemmiIconButton
                faIcon="fa-save"
                onClick={() => onSave()}
                disabled={!address?.address || !DemmiHelpers.Customer.isAddressValid(address!)}
              />
            </>
          )}
        </div>
      </div>

      <div className={`${CSSBlock}__details`}>
        {!!address &&
          !!addressDefinitions &&
          Object.entries(addressDefinitions).map(([key, val], i) => (
            <div className={`${CSSBlock}__item`} key={i}>
              <div className={`${CSSBlock}__item-key`}>{val.label}</div>
              <div
                className={`${CSSBlock}__item-value ${
                  val.validate !== null && val.validate!(DemmiFS.DemmiAddressHelper.getFieldValue(key, address!))
                    ? ''
                    : `${CSSBlock}__item-value--error`
                }`}>
                {editMode
                  ? editModeInput(key, val)
                  : (DemmiFS.DemmiAddressHelper.getFieldValue(key, customer.address!) ?? '-')}
              </div>
            </div>
          ))}

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Region</div>
          <div className={`${CSSBlock}__item-value`}>
            {customer?.address?.countryCode !== null && customer.address.countryCode}
          </div>
        </div>
      </div>

      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default CustomerAddress;
