import { format } from 'date-fns';
import React from 'react';

import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  code: DemmiFS.InviteCode;
}

const InviteCodesSidebarButton: React.FC<Props> = ({ code, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div className={`${CSSBlock}`}>
      <span className={`${CSSBlock}__code`}>{`${code.code.substring(0, 3)}-${code.code.substring(3)}`}</span>
      <span className={`${CSSBlock}__email`}>{`${code.email}`}</span>

      {code.createdAt && (
        <div className={`${CSSBlock}__key-val`}>
          <span className={`${CSSBlock}__key`}>Created at:</span>
          <span className={`${CSSBlock}__val`}>
            {format(DemmiFS.getDateFromFirestore(code.createdAt)!, 'dd MMM yyyy, HH:mm:ss')}
          </span>
        </div>
      )}
      {code.activated && code.activatedAt && (
        <div className={`${CSSBlock}__key-val`}>
          <span className={`${CSSBlock}__key`}>Activated at:</span>
          <span className={`${CSSBlock}__val`}>
            {format(DemmiFS.getDateFromFirestore(code.activatedAt)!, 'dd MMM yyyy, HH:mm:ss')}
          </span>
        </div>
      )}
      {code.activated ? (
        <span className={`${CSSBlock}__label ${CSSBlock}__label--redeemed`}>Redeemed</span>
      ) : code.revoked ? (
        <span className={`${CSSBlock}__label ${CSSBlock}__label--revoked`}>Revoked</span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InviteCodesSidebarButton;
