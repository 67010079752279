import React, { HTMLProps, PropsWithChildren } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';

interface DemmiCardProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  rootClassName?: string;
  buttons?: JSX.Element[];
  titleButton?: JSX.Element;
  title?: string;
  subtitle?: string;
  darkOnly?: boolean;
  isLoading?: boolean;
}

const DemmiCard: React.FC<DemmiCardProps> = ({
  children,
  title,
  subtitle,
  titleButton,
  darkOnly,
  isLoading,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';

  return (
    <div
      {...props}
      className={`${CSSBlock} ${darkOnly ? `${CSSBlock}--dark-only` : ''} ${props.className ? props.className : ``} ${
        isLoading ? `${CSSBlock}--loading` : ``
      }`}>
      {title && (
        <div className={`${CSSBlock}__title`}>
          {subtitle ? (
            <span>
              {title}
              <span className={`${CSSBlock}__subtitle`}>{subtitle}</span>
            </span>
          ) : (
            <>{title}</>
          )}
          {titleButton && titleButton}
        </div>
      )}
      <span className={`${CSSBlock}__content ${props.className ? props.className : ``}`}>{children}</span>
      <ElementLoadingIndicator isLoading={isLoading ?? false} />
    </div>
  );
};

export default DemmiCard;
