import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ComingSoon from '@components/comingSoon/ComingSoon';
import Page from '@components/page/Page';
import { useAppSelector } from '@core/hooks/appHooks';
import { getOrders } from '@core/services/networkService/orders/orders';
import { RouteParams, RoutingViews, getRouteFromPath } from '@core/services/routingService';
import { selectCustomer } from '@core/store/userSlice/userSlice';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

import OrderHistorySidebarActiveItemMobileView from './_sidebarActiveItemMobileView';
import OrderHistorySidebarButton from './_sidebarButton';

const OrderHistory: React.FC = () => {
  const CSSBlock = 'order-history';

  const customer = useAppSelector(selectCustomer);
  const navigate = useNavigate();
  const { [RouteParams.ORDER_ID]: orderID } = useParams();

  const [orders, setOrders] = useState<DemmiFS.Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<DemmiFS.Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<DemmiFS.Order | undefined>();

  useEffect(() => {
    const order = orders.find(f => f.docID === orderID);
    if (order) {
      setSelectedOrder(undefined);
      setTimeout(() => {
        if (order) setSelectedOrder(order);
      }, 50);
    }
  }, [orderID, orders]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredOrders(orders), [orders]);

  const onClickOrder = (index: number) => {
    const order = orders[index];
    Logger({ objs: { order } }, onClickOrder);
    setSelectedOrder(undefined);
    navigate(
      getRouteFromPath(RoutingViews.ORDER_HISTORY_DETAILS, {
        [RouteParams.ORDER_ID]: order.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (order) setSelectedOrder(order);
    }, 50);
  };

  const filterOrders = (filter: string) => {
    const filtered = orders.reduce((acc: DemmiFS.Order[], cur: DemmiFS.Order) => {
      if (filter && !cur.orderID.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredOrders(filtered);
  };

  const onClickRefresh = () => {
    if (customer) demmiRequest(getOrders(customer.docID)).then(f => setOrders(f));
    setSelectedOrder(undefined);
  };

  const sidebarButtons = () =>
    filteredOrders.map(order => <OrderHistorySidebarButton CSSBlock={CSSBlock} order={order} key={order.docID} />);
  const sidebarActiveItemMobileView = () => (
    <OrderHistorySidebarActiveItemMobileView CSSBlock={CSSBlock} selectedOrder={selectedOrder} />
  );

  const sidebarTitleButtons = [{ faIcon: 'fa-rotate-right', onClick: onClickRefresh }];

  return (
    <Page
      sidebarFixed={true}
      sidebarTitleButtons={sidebarTitleButtons}
      sidebarButtons={sidebarButtons()}
      sidebarButtonDivider={<div className={`${CSSBlock}__sidebar-button-divider`} />}
      sidebarActiveItemMobileView={sidebarActiveItemMobileView()}
      sidebarActive={orders.findIndex(f => selectedOrder?.docID === f.docID)}
      sidebarTitle="Your Orders"
      sidebarOnClick={onClickOrder}
      sidebarOnSearch={filterOrders}
      sidebarSearchPlaceholder="Filter Orders">
      <ComingSoon />
      {/* {selectedOrder && <InviteOrderView code={selectedOrder} />} */}
    </Page>
  );
};

export default OrderHistory;
