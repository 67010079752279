import { Logger } from '@subhanhabib/demmilib';

export enum CloudFunctions {
  ON_SETUP_COMPLETE = 'http-users-setupComplete',
  SEND_INVITE_CODE_EMAIL = 'http-customerPortal-sendInviteCodeEmail',
  REVOKE_INVITE_CODE = 'http-customerPortal-revokeInviteCode',
}

export const Cloud_Function_Base_URL_DEV = 'https://us-central1-demmi-dev.cloudfunctions.net';
export const Cloud_Function_Base_URL_PROD = 'https://us-central1-demmi-prod.cloudfunctions.net';

const getCloudFunctionBaseURL = () =>
  process.env.IS_PRODUCTION ? Cloud_Function_Base_URL_PROD : Cloud_Function_Base_URL_DEV;

export const getCloudFunctionURL = (func: CloudFunctions) => {
  Logger({ objs: { url: `${getCloudFunctionBaseURL()}/${func}` } }, getCloudFunctionURL);
  return `${getCloudFunctionBaseURL()}/${func}`;
};

export enum FSCollectionNames {
  PRODUCTS = 'products',
  VENDORS = 'vendors',
  VENDOR_USER = 'vendor_user',
  USERS = 'users',
  ORDERS = 'orders',
  COUPONS = 'coupons',
  DEMMI = 'demmi',
  CATEGORIES = 'categories',
  REFUND_REQUEST = 'refund_requests',
  DEMMI_SUPPORT = 'demmi_support',
  NOTIFICATIONS_VENDOR = 'notifications_vendor',
  INVITE_CODES = 'invite_codes',
}

export enum FSSubCollectionNames {
  VARIANTS = 'variants',
  REVIEWS = 'reviews',
  DEMMI_SUPPORT_MESSAGES = 'messages',
}
