import { format } from 'date-fns';
import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  order: DemmiFS.Order;
}

const OrderHistorySidebarButton: React.FC<Props> = ({ order, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__vendors`}>
        {Object.entries(order.vendors).map(([vendorID, vendor]) => (
          <div className={`${CSSBlock}__vendor`} style={{ '--local-vendor-hue': vendor.themeHue }} key={vendorID}>
            <div className={`${CSSBlock}__vendor-thumbnail`}>
              <AsyncImage vendorID={vendorID} isVendor src={vendor.image} />
            </div>
            <div className={`${CSSBlock}__vendor-content`}>
              <div className={`${CSSBlock}__vendor-name`}>{vendor.name}</div>
              <div className={`${CSSBlock}__vendor-cost`}>
                {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(vendor.cost.currencyCode)}
                {Number(vendor.cost.gross).toFixed(2)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${CSSBlock}__footer`}>
        <span className={`${CSSBlock}__date`}>
          {format(DemmiFS.getDateFromFirestore(order.orderDate!) ?? '', 'dd MMM yyyy, HH:mm:ss')}
        </span>
        <span className={`${CSSBlock}__total-cost`}>
          {DemmiFS.CurrencyHelper.getCurrencySymbolFromCurrencyCode(order.cost.currencyCode)}
          {Number(order.cost.gross).toFixed(2)}
        </span>
      </div>
    </div>
  );
};

export default OrderHistorySidebarButton;
