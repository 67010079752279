import { httpsCallable } from 'firebase/functions';

import { Logger } from '@subhanhabib/demmilib';

import { functions } from '../../../firebase';
import { CloudFunctions } from './networkService';

export const onSetupCustomer = async (customerDocID: string) => {
  const addMessage = httpsCallable(functions, CloudFunctions.ON_SETUP_COMPLETE);
  addMessage({ customerDocID }).then(_ => {
    // Read result of the Cloud Function.
    // /** @type {any} */
    // const data = result.data;
    Logger({ objs: { FormData } }, onSetupCustomer);
  });
};
