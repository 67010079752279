import React, { PropsWithChildren } from 'react';
import { To } from 'react-router-dom';

import LogoWhite from '@assets/demmi-logo-white.svg';
import Logo from '@assets/demmi-logo.svg';
import { useAppDispatch } from '@core/hooks/appHooks';

import { RoutingViews, getViewFromPath, routingPaths } from '@core/services/routingService';

import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { useAuth } from '@providers/authProvider';

import ThemeSwitcher from '../themeSwitcher/ThemeSwitcher';
import PortalSidebarButton, { PortalSidebarButtonProps } from './PortalSidebarButton';
import { getAppDate, getAppVersion } from '@helpers/app.helper';
import { DemmiHelpers } from '@subhanhabib/demmilib';

interface Props extends PropsWithChildren {
  navigateTransitioned: (to: To) => void;
}

const PortalSidebar: React.FC<Props> = ({ navigateTransitioned }) => {
  const CSSBlock = 'portal-sidebar';
  const dispatch = useAppDispatch();
  const view = getViewFromPath(location.pathname, false);
  const { logOut } = useAuth();

  const buttons: PortalSidebarButtonProps[] = [
    {
      text: 'Home',
      faIcon: 'fa-light fa-home',
      active: view === RoutingViews.HOME,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.HOME]),
    },
    {
      text: 'Invite Codes',
      faIcon: 'fa-light fa-ticket',
      active: view === RoutingViews.INVITE_CODES,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.INVITE_CODES]),
    },
    {
      text: 'Order History',
      faIcon: 'fa-light fa-basket-shopping',
      active: view === RoutingViews.ORDER_HISTORY,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.ORDER_HISTORY]),
    },
    {
      text: 'Your Account',
      faIcon: 'fa-light fa-user',
      active: view === RoutingViews.YOUR_ACCOUNT,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.YOUR_ACCOUNT]),
    },
    {
      text: 'Settings',
      // disabled: true,
      faIcon: 'fa-light fa-cog',
      active: view === RoutingViews.SETTINGS,
      onClick: () => navigateTransitioned(routingPaths[RoutingViews.SETTINGS]),
    },
  ];

  return (
    <div className={`${CSSBlock}`}>
      {/* <DemmiButton
        text="Test email"
        invert
        onClick={() => {
          onSetupCustomer('TreVlqznGRjkbTU9FO6j');
        }}
      /> */}
      <div className={`${CSSBlock}__logo`}>
        <img src={Logo} className={`${CSSBlock}__logo-colour`} />
        <img src={LogoWhite} className={`${CSSBlock}__logo-white`} />
      </div>
      <div className={`${CSSBlock}__buttons`}>
        {buttons.map((button, i) => (
          <PortalSidebarButton key={i} {...button} />
        ))}
      </div>
      <div className={`${CSSBlock}__bottom`}>
        <div className={`${CSSBlock}__logout-button`}>
          <DemmiIconButton
            invert
            faIcon="fa-arrow-right-from-bracket"
            onClick={() => logOut(() => dispatch({ type: 'LOGOUT' }))}
          />
        </div>
        <ThemeSwitcher />
      </div>
      {!DemmiHelpers.App.isProduction() && (
        <div className={`${CSSBlock}__version`}>
          <div className={`${CSSBlock}__version-build`}>
            <span className={`${CSSBlock}__version-key`}>Build version</span>
            <span className={`${CSSBlock}__version-val`}>{getAppVersion()}</span>
          </div>
          <div className={`${CSSBlock}__version-date`}>
            <span className={`${CSSBlock}__version-key`}>Build date</span>
            <span className={`${CSSBlock}__version-val`}>{getAppDate()}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortalSidebar;
