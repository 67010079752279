import { format } from 'date-fns';
import React, { useState } from 'react';

import { resendInviteCodeEmail, revokeInviteCode } from '@core/services/networkService/inviteCodes/inviteCodes';
import DemmiButton from '@demmi-ui/Buttons/Button';
import { faCheck, faRotateLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DemmiFS, DemmiHelpers, DemmiHooks, Logger } from '@subhanhabib/demmilib';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  code: DemmiFS.InviteCode;
  refreshCode: () => void;
}

const InviteCodeContent: React.FC<Props> = ({ code, refreshCode }) => {
  const CSSBlock = 'invite-code-view';

  const { isDisabled, timeRemaining } = DemmiHooks.useTimeRemaining(
    DemmiFS.getDateFromFirestore(code.inviteSentAt)?.getTime(),
    3600 * 1000,
  );
  const [isSending, setIsSending] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const onClickRevoke = async () => {
    setIsRevoking(true);

    await revokeInviteCode(code.docID)
      .then(_ => {})
      .catch(e => Logger({ objs: { e } }, onClickRevoke));

    setIsRevoking(false);
    refreshCode();
  };

  const onClickResend = async () => {
    setIsSending(true);

    await resendInviteCodeEmail(code.docID)
      .then(_ => {})
      .catch(e => Logger({ objs: { e } }, onClickResend));

    setIsSending(false);
    refreshCode();
  };

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <div className={`${CSSBlock}__envelope`}>
        <div className={`${CSSBlock}__paper`}>
          <div className={`${CSSBlock}__code`}>{`${code.code.substring(0, 3)}-${code.code.substring(3)}`}</div>
          <div className={`${CSSBlock}__email`}>{`${code.email}`}</div>

          <div className={`${CSSBlock}__key-val`}>
            <span className={`${CSSBlock}__key`}>Created at:</span>
            <span className={`${CSSBlock}__val`}>
              {code.createdAt ? format(DemmiFS.getDateFromFirestore(code.createdAt)!, 'dd MMM yyyy, HH:mm:ss') : '-'}
            </span>
          </div>

          <div className={`${CSSBlock}__key-val`}>
            <span className={`${CSSBlock}__key`}>Activated at:</span>
            <span className={`${CSSBlock}__val`}>
              {code.activatedAt
                ? format(DemmiFS.getDateFromFirestore(code.activatedAt)!, 'dd MMM yyyy, HH:mm:ss')
                : '-'}
            </span>
          </div>
          <span className={`${CSSBlock}__tint`}></span>
        </div>
        <span className={`${CSSBlock}__tint`}></span>
        {code.activated && (
          <div className={`${CSSBlock}__sticker ${CSSBlock}__sticker--redeemed`}>
            <FontAwesomeIcon icon={faCheck} />
            Redeemed
          </div>
        )}
        {code.revoked && (
          <div className={`${CSSBlock}__sticker ${CSSBlock}__sticker--revoked`}>
            <FontAwesomeIcon icon={faXmark} />
            Revoked
          </div>
        )}
      </div>
      {!code.activated && !code.revoked && (
        <div className={`${CSSBlock}__controls`}>
          <DemmiButton
            text="Revoke Code"
            icon={faRotateLeft}
            disabled={isSending}
            isLoading={isRevoking}
            onClick={onClickRevoke}
          />
          <div className={`${CSSBlock}__button-with-subtext`}>
            <DemmiButton
              text="Resend invite code"
              disabled={isDisabled || isRevoking}
              isLoading={isSending}
              icon={faPaperPlaneTop}
              onClick={onClickResend}
            />
            {isDisabled && (
              <div className={`${CSSBlock}__subtext`}>
                Wait {DemmiHelpers.Time.durationToTimeString(timeRemaining)}
                <br />
                before resending email
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteCodeContent;
