import React, { useEffect, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { UserRegion, getRegionFromLatLng, getUserLocation, locationPermissionsCheck } from '@helpers/location.helper';
import { isScreen01Valid } from '@helpers/welcome.helper';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  updateUserRegion: (region?: UserRegion) => void;
}
const Screen01: React.FC<Props> = ({ isMounted, mountedStyle, unmountedStyle, updateUserRegion }) => {
  const CSSBlock = 'welcome-section';

  /**
   * -1 = permissions error
   * 0 = none
   * 1 = requesting permission
   * 2 = getting location
   * 3 = got location
   * 4 = error getting location
   * 5 = invalid region
   */
  const [locationState, setLocationState] = useState(0);
  const [userRegion, setUserRegion] = useState<UserRegion>();

  const _locationCheck = async () => {
    const permissionStatus = await locationPermissionsCheck();
    if (permissionStatus.state === 'denied') {
      return false;
    }
    return true;
  };

  const getRegion = async () => {
    setLocationState(1);
    const hasLocationPermission = await _locationCheck();
    if (hasLocationPermission) {
      setLocationState(2);
      const [lat, lng] = await getUserLocation();
      const region = await getRegionFromLatLng(lat, lng);
      if (region) {
        setUserRegion(region);
        updateUserRegion(region);

        isScreen01Valid(region.countryCode ?? '') ? setLocationState(3) : setLocationState(5);
      } else {
        setLocationState(4);
      }
    } else {
      setLocationState(-1);
    }
  };

  useEffect(() => {
    getRegion();
  }, []);

  const statePermissionError = () => {
    return (
      <div className={`${CSSBlock}__state-1`}>
        <div className={`${CSSBlock}__state-title`}>Oh no!</div>
        <div className={`${CSSBlock}__state-message`}>
          It looks like your browser is blocking us from getting your location.
        </div>
        <div className={`${CSSBlock}__state-message`}>
          Check you browser&apos;s location permission settings and try again.
        </div>
      </div>
    );
  };
  const state0 = () => {
    return (
      <div className={`${CSSBlock}__state0`}>
        <div className={`${CSSBlock}__state-title`}>We need your permission</div>
        <div className={`${CSSBlock}__state-message`}>
          We&apos;ll need access to your location to check if Demmi is available in your area.
        </div>
      </div>
    );
  };
  const state1 = () => {
    return (
      <div className={`${CSSBlock}__state1`}>
        <div className={`${CSSBlock}__state-title`}>We need your permission</div>
        <div className={`${CSSBlock}__state-message`}>
          We&apos;ll need access to your location to check if Demmi is available in your area.
        </div>
        <div className={`${CSSBlock}__state-loading-indicator`}>
          <ElementLoadingIndicator isLoading={true} />
        </div>
      </div>
    );
  };
  const state2 = () => {
    return (
      <div className={`${CSSBlock}__state2`}>
        <div className={`${CSSBlock}__state-title`}>Tracking you down...</div>
        <div className={`${CSSBlock}__state-message`}>We&apos;re just checking where you are...</div>
        <div className={`${CSSBlock}__state-loading-indicator`}>
          <ElementLoadingIndicator isLoading={true} />
        </div>
      </div>
    );
  };
  const state3 = () => {
    return (
      <div className={`${CSSBlock}__state3`}>
        <div className={`${CSSBlock}__state-message`}>We&apos;ve detected you&apos;re in</div>
        <div className={`${CSSBlock}__region`}>{userRegion ? `${userRegion.city}, ${userRegion.country}` : ''}</div>
        <div className={`${CSSBlock}__state-message`}>Is that correct?</div>
        <div className={`${CSSBlock}__state-message-block`}>
          If not, check your internet connection and make sure you&apos;re not using a VPN during the setup. We need to
          make sure Demmi has rolled out in your area.
        </div>
      </div>
    );
  };
  const state4 = () => {
    return (
      <div className={`${CSSBlock}__state4`}>
        <div className={`${CSSBlock}__state-title`}>Oh no!</div>
        <div className={`${CSSBlock}__state-message`}>We were unable to get your location.</div>
        <div className={`${CSSBlock}__state-message`}>
          Check you browser&apos;s location permission settings and try again.
        </div>
      </div>
    );
  };
  const state5 = () => {
    return (
      <div className={`${CSSBlock}__state5`}>
        <div className={`${CSSBlock}__state-title`}>Ah shucks!!</div>
        <div className={`${CSSBlock}__state-message`}>We&apos;ve detected you&apos;re in</div>
        <div className={`${CSSBlock}__region`}>{userRegion ? `${userRegion.city}, ${userRegion.country}` : ''}</div>
        <div className={`${CSSBlock}__state-message-block`}>
          Unfortunately, Demmi isn&apos;t currently available in your area.
        </div>
        <div className={`${CSSBlock}__state-message-block`}>
          Don&apos;t worry though, we&apos;re working hard to expand the rollout to many more areas.
        </div>
      </div>
    );
  };

  const getViewFromState = () => {
    switch (locationState) {
      case -1:
        return statePermissionError();
      case 0:
        return state0();
      case 1:
        return state1();
      case 2:
        return state2();
      case 3:
        return state3();
      case 4:
        return state4();
      case 5:
        return state5();
      default:
        return state0();
    }
  };

  return (
    <ScreenWrapper
      cardIndex={1}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={'Your Region'}>
      {getViewFromState()}
    </ScreenWrapper>
  );
};

export default Screen01;
