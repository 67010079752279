import { query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

// ----------
// Common Queries
// ----------

export const getInviteCodesQuery = (customerDocID: string) =>
  query(FSCollections.InviteCodes, where(`sourceCustomerID`, '==', customerDocID));

export const isInviteCodeEmailAvailableQuery = (email: string) =>
  query(FSCollections.InviteCodes, where('email', '==', email));

export const isInviteCodeQuery = (code: string) => query(FSCollections.InviteCodes, where('code', '==', code));
