import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@core/hooks/appHooks';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { setCustomer } from '@core/store/userSlice/userSlice';
import DemmiButton from '@demmi-ui/Buttons/Button';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { isScreen01Valid, isScreen02Valid, isScreen03Valid } from '@helpers/welcome.helper';
import { useAuth } from '@providers/authProvider';
import { DemmiFS, DemmiHelpers, DemmiHooks, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import WelcomeFooter from './WelcomeFooter';
import WelcomeHeader from './WelcomeHeader';
import Screen00 from './partials/Screen00';
import Screen01 from './partials/Screen01';
import Screen02 from './partials/Screen02';
import Screen03 from './partials/Screen03';
import Screen04 from './partials/Screen04';
import Screen05 from './partials/Screen05';
import Screen06 from './partials/Screen06';

const transitionTime = 300;
const sectionMount = {
  animation: `welcomeInAnimation ${transitionTime + 50}ms ease-in-out`,
};
const sectionUnmount = {
  animation: `welcomeOutAnimation ${transitionTime + 50}ms ease-in-out`,
};

const Welcome: React.FC = () => {
  const CSSBlock = 'welcome';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAuth();
  const [authProvider, setAuthProvider] = useState<DemmiFS.AuthProviderHelper.FBAuthProviderInfo>();

  const [customerDetails, setCustomerDetails] = useState<DemmiFS.FSUser>(DemmiHelpers.Customer.EMPTY_CUSTOMER);
  const [finishedCustomer, setFinishedCustomer] = useState<DemmiFS.User>();

  useEffect(() => {
    if (user && user.providerData.length > 0) {
      const provider = user.providerData[0];
      setAuthProvider({
        email: provider.email ?? user.email ?? undefined,
        uid: user.uid,
        providerID: provider.providerId,
      });
    }
  }, [user]);

  useEffect(() => {
    const provider = authProvider
      ? DemmiFS.AuthProviderHelper.getFBAuthProviderByID(authProvider.providerID)
      : undefined;
    setCustomerDetails({
      ...(process.env.IS_PRODUCTION ? DemmiHelpers.Customer.EMPTY_CUSTOMER : DemmiHelpers.Customer.MOCK_CUSTOMER),
      ...(provider ? { fb_authProviders: { [provider]: authProvider } } : {}),
    });
  }, [authProvider]);

  /**
   *  Conditional rendering + transitions
   *  Screens:
   *    0 = Welcome
   *    1 = Region
   *    2 = Your Details
   *    3 = Contact Details
   *    4 = Check Details
   *    5 = Processing...
   *    6 = Finish
   */
  const screens = 7;
  const processingScreenIndex = 5;
  const finishedScreenIndex = 6;
  const [mounted, setMounted] = useState(0);
  const shouldRender0 = DemmiHooks.useDelayUnmount(mounted === 0, transitionTime);
  const shouldRender1 = DemmiHooks.useDelayUnmount(mounted === 1, transitionTime);
  const shouldRender2 = DemmiHooks.useDelayUnmount(mounted === 2, transitionTime);
  const shouldRender3 = DemmiHooks.useDelayUnmount(mounted === 3, transitionTime);
  const shouldRender4 = DemmiHooks.useDelayUnmount(mounted === 4, transitionTime);
  const shouldRender5 = DemmiHooks.useDelayUnmount(mounted === 5, transitionTime);
  const shouldRender6 = DemmiHooks.useDelayUnmount(mounted === 6, transitionTime);

  const buttonTexts = [
    "Let's get started",
    "Yes, that's correct",
    'Contact Information',
    'Next',
    "That's perfect!",
    'Processing',
    'Processing',
  ];

  const setupUser = async () => {
    if (!user?.uid) {
      Logger({ messages: ['No user found'], objs: { user }, type: DemmiLogType.error }, setupUser);
      return;
    }

    const provider =
      user.providerData.length > 0
        ? DemmiFS.AuthProviderHelper.getFBAuthProviderByID(user.providerData[0].providerId)
        : undefined;
    if (!provider) {
      Logger(
        { messages: ['Failed to get auth provider'], objs: { user, provider }, type: DemmiLogType.error },
        setupUser,
      );
      return;
    }

    setMounted(processingScreenIndex);
    FSCustomer.profile.setupCustomer(user.uid, provider, customerDetails).then(async () => {
      const customer = await FSCustomer.doc.getByProvider(user.uid, provider);
      setFinishedCustomer(customer);
      setTimeout(() => {
        setMounted(finishedScreenIndex);
      }, 2000);
    });
  };

  const onClickFinish = () => {
    if (finishedCustomer) {
      dispatch(setCustomer(finishedCustomer));
      navigate(routingPaths[RoutingViews.HOME], { replace: true });
    }
  };

  useEffect(() => {
    if (mounted === processingScreenIndex) setupUser();
  }, [mounted]);

  const next = () => {
    if (mounted < processingScreenIndex) setMounted(Math.min(mounted + 1, screens - 1));
  };
  const previous = () => setMounted(Math.max(mounted - 1, 0));

  const isValid = () => {
    if (mounted === 1) {
      return isScreen01Valid(customerDetails.address.countryCode);
    }
    if (mounted === 2) {
      return isScreen02Valid(
        customerDetails.title,
        customerDetails.firstName,
        customerDetails.lastName,
        customerDetails.nickname,
      );
    }
    if (mounted === 3) {
      return isScreen03Valid(customerDetails.contactNumber);
    }
    return true;
  };

  return (
    <>
      <div className={`${CSSBlock}__wrapper`}>
        <WelcomeHeader />
        <div className={`${CSSBlock}`}>
          <div className={`${CSSBlock}__container`}>
            <div className={`${CSSBlock}__progress`}>
              <div
                className={`${CSSBlock}__progress-bar`}
                style={{
                  flexBasis: `${Math.min(100, ((mounted + 1) / processingScreenIndex) * 100)}%`,
                }}></div>
            </div>
            {user && authProvider && (
              <div className={`${CSSBlock}__content`}>
                {shouldRender0 && (
                  <Screen00
                    user={user}
                    isMounted={mounted === 0}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                  />
                )}
                {shouldRender1 && (
                  <Screen01
                    isMounted={mounted === 1}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                    updateUserRegion={userRegion =>
                      setCustomerDetails({
                        ...customerDetails,
                        address: {
                          ...customerDetails.address,
                          countryCode: userRegion?.countryCode ?? '',
                        },
                      })
                    }
                  />
                )}
                {shouldRender2 && (
                  <Screen02
                    isMounted={mounted === 2}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                    customer={customerDetails}
                    onUpdateCustomer={val => setCustomerDetails(val)}
                  />
                )}
                {shouldRender3 && (
                  <Screen03
                    isMounted={mounted === 3}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                    authProvider={authProvider}
                    customer={customerDetails}
                    onUpdateCustomer={val => setCustomerDetails(val)}
                  />
                )}
                {shouldRender4 && (
                  <Screen04
                    isMounted={mounted === 4}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                    customer={customerDetails}
                  />
                )}
                {shouldRender5 && (
                  <Screen05 isMounted={mounted === 5} mountedStyle={sectionMount} unmountedStyle={sectionUnmount} />
                )}
                {shouldRender6 && (
                  <Screen06
                    isMounted={mounted === 6}
                    mountedStyle={sectionMount}
                    unmountedStyle={sectionUnmount}
                    onClickFinish={onClickFinish}
                  />
                )}
              </div>
            )}
            {mounted !== finishedScreenIndex && (
              <div className={`${CSSBlock}__buttons`}>
                <DemmiIconButton
                  faIcon="fa-chevron-left"
                  onClick={() => previous()}
                  disabled={mounted === 0 || mounted >= processingScreenIndex}
                />
                <DemmiButton
                  text={buttonTexts[mounted]}
                  faIcon="fa-chevron-right"
                  onClick={() => next()}
                  disabled={!isValid() || mounted === processingScreenIndex}
                />
              </div>
            )}
          </div>
        </div>
        <WelcomeFooter />
      </div>
    </>
  );
};

export default Welcome;
