import React, { useState } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, defer } from 'react-router-dom';

import ComingSoon from '@components/comingSoon/ComingSoon';
import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import NotFound404 from '@pages/404/NotFound404';
import InviteCodes from '@pages/inviteCodes/InviteCodes';
import Login from '@pages/login/Login';
import OrderHistory from '@pages/orderHistory/OrderHistory';
import Welcome from '@pages/welcome/Welcome';
import YourAccount from '@pages/yourAccount/YourAccount';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

// import { Logger } from '@subhanhabib/demmilib';
import { auth } from '../firebase';
import Home from '../pages/home/Home';
import AuthLayout from './AuthLayout';
import { useAppDispatch } from './hooks/appHooks';
import AccountSetupRoute from './routeGuards/AccountSetupRoute';
import ProtectedRoute from './routeGuards/ProtectedRoute';
import UnprotectedRoute from './routeGuards/UnprotectedRoute';
import { FSCustomer } from './services/networkService/customer/customer';
import { RoutingViews, routingPaths } from './services/routingService';
import { setCustomer, setIsFetchingCustomer } from './store/userSlice/userSlice';

const AppCore: React.FC = () => {
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [_, setIsLoadingContent] = useState(false);
  const dispatch = useAppDispatch();

  // Necessary because auth is always null before initialising,
  // and Firebase doesn't return a promise for initialisation.
  const getUser = () => {
    Logger({ messages: 'getUser' }, AppCore);
    dispatch(setIsFetchingCustomer(true));
    new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
        Logger({ objs: { user } }, AppCore);

        if (user && user.providerData.length > 0) {
          const provider = DemmiFS.AuthProviderHelper.getFBAuthProviderByID(user.providerData[0].providerId);
          if (provider) {
            Logger({ objs: { provider: provider } }, AppCore);
            (provider === DemmiFS.AuthProviderHelper.FBAuthProvider.apple
              ? FSCustomer.doc.getByProviderApple
              : FSCustomer.doc.getByProviderGoogle)(user.uid).then(customer => {
              Logger({ objs: customer }, AppCore);
              dispatch(setCustomer(customer));
              dispatch(setIsFetchingCustomer(false));
            });
          } else {
            Logger(
              { messages: ['Failed to parse provider'], objs: { provider: provider }, type: DemmiLogType.error },
              AppCore,
            );
            dispatch(setIsFetchingCustomer(false));
          }
        } else {
          dispatch(setCustomer(undefined));
          dispatch(setIsFetchingCustomer(false));
        }
      }, reject);
    });
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthLayout />} loader={() => defer({ user: getUser() })} errorElement={<NotFound404 />}>
        <Route element={<UnprotectedRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route element={<AccountSetupRoute />}>
          <Route path={RoutingViews.USER_SETUP} element={<Welcome />} />
        </Route>

        <Route path="/" element={<ProtectedRoute />}>
          <Route path={RoutingViews.HOME} element={<Home />} />

          <Route path={routingPaths[RoutingViews.INVITE_CODES]} element={<InviteCodes />}>
            <Route path={routingPaths[RoutingViews.INVITE_CODES_DETAIL]} element={<InviteCodes />} />
          </Route>

          <Route path={routingPaths[RoutingViews.ORDER_HISTORY]} element={<OrderHistory />}>
            <Route path={routingPaths[RoutingViews.ORDER_HISTORY_DETAILS]} element={<OrderHistory />} />
          </Route>

          <Route path={RoutingViews.YOUR_ACCOUNT} element={<YourAccount />} />
          <Route path={RoutingViews.SETTINGS} element={<ComingSoon />} />
        </Route>
      </Route>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />

      {isLoadingIntro && (
        <LoadingIntro onAnimStart={() => setIsLoadingContent(true)} onAnimEnd={() => setIsLoadingIntro(false)} />
      )}
    </>
  );
};

export default AppCore;
