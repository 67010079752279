import React, { HTMLProps, PropsWithChildren } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';

import DemmiCardArea from './ModalArea';

interface Props extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  buttons: JSX.Element[];
  isDoingRequest?: boolean;
}

const DemmiModalButtonArea: React.FC<Props> = ({ buttons, isDoingRequest, ...props }) => {
  const CSSBlock = 'demmi-ui-modal';

  return (
    <DemmiCardArea
      {...props}
      className={`${CSSBlock}__area-buttons ${props.className ? props.className : ``}  ${
        isDoingRequest ? `${CSSBlock}__area-buttons--doing-request` : ``
      }`}>
      <div className={`${CSSBlock}__action-buttons`}>{buttons}</div>
      <ElementLoadingIndicator isLoading={!!isDoingRequest} />
    </DemmiCardArea>
  );
};

export default DemmiModalButtonArea;
