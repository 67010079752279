import React, { useEffect, useRef, useState } from 'react';

import {
  createInviteCode,
  isEmailAvailable,
  resendInviteCodeEmail,
} from '@core/services/networkService/inviteCodes/inviteCodes';
import DemmiButton from '@demmi-ui/Buttons/Button';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiModalButtonArea from '@demmi-ui/Modal/ModalButtonArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/Modal/ModalCore';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiHelpers, DemmiHooks, Logger } from '@subhanhabib/demmilib';

import ModalCreateInviteCodeContent from './ModalCreateInviteCodeContent';

interface Props {
  customerDocID?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalCreateInviteCode: React.FC<Props> = ({ customerDocID, onClose, onConfirm }) => {
  const CSSBlock = 'modal-create-invite-code';
  const modalRef = useRef<DemmiModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState<string>();

  const debouncedInput = DemmiHooks.useDebounce(email, 500);

  /**
   * 0 = Doing nothing
   * 1 = Checking email in use
   * 2 = Email available
   * 3 = Creating code
   * -1 = Email already in use
   * -2 = Failed to update
   */
  const [state, setState] = useState(0);

  const checkEmail = () => {
    setState(1);
    isEmailAvailable(email).then(b => {
      if (b) {
        setState(2);
        setCode(DemmiHelpers.InviteCodes.generate(email));
      } else {
        setState(-1);
        setCode(undefined);
      }
    });
  };

  useEffect(() => {
    setState(0);
    if (email.length > 0 && DemmiHelpers.InviteCodes.isValidEmail(email)) checkEmail();
    else setCode(undefined);
  }, [debouncedInput]);

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
    setTimeout(() => {
      setIsOpen(false);
      setState(0);
    }, 500);
  };

  const pushCode = () => {
    Logger({ objs: { customerDocID, code, state } }, pushCode);
    if (customerDocID && code && state === 2) {
      demmiRequest(
        createInviteCode({
          code: code.replaceAll('-', ''),
          email,
          sourceCustomerID: customerDocID,
          activated: false,
          revoked: false,
        }),
      )
        .catch(() => setState(-1))
        .then(id => {
          if (typeof id === 'string') resendInviteCodeEmail(id);
          // TODO: if (id === false) show error toast/message
          gracefulExit(() => onConfirm());
        });
    }
  };

  const buttons = [
    <DemmiButton key={0} text="Cancel" onClick={() => gracefulExit(onClose)} />,
    <DemmiButton key={1} text="Send Invite" disabled={state !== 2} onClick={pushCode} />,
  ];

  return (
    <>
      <DemmiIconButton faIcon="fa-plus" onClick={() => setIsOpen(true)} />
      <DemmiModalCore
        ref={modalRef}
        isOpen={isOpen}
        onExited={() => {
          setIsOpen(false);
          onClose();
        }}
        contentClassName={CSSBlock}
        allowBackdropClose={false}>
        <ModalCreateInviteCodeContent CSSBlock={CSSBlock} state={state} code={code} email={email} setEmail={setEmail} />
        <DemmiModalButtonArea buttons={buttons} />
      </DemmiModalCore>
    </>
  );
};

export default ModalCreateInviteCode;
