import { Auth, GoogleAuthProvider, OAuthProvider, User, UserCredential, signInWithPopup, signOut } from 'firebase/auth';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Logger } from '@subhanhabib/demmilib';

import { auth } from '../firebase';

export interface AuthProviderProps {
  userData: User | null;
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signInGoogle: () => Promise<UserCredential>;
  signInApple: () => Promise<UserCredential | null>;
  logOut: (onLogout?: () => void) => void;
}

export const AuthContext = createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ userData, children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(userData);
  const navigate = useNavigate();

  const signInGoogle = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const signInApple = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return signInWithPopup(auth, provider)
      .then(result => {
        setUser(result.user);
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          // const accessToken = credential.accessToken;
          // const idToken = credential.idToken;
        }
        Logger({ objs: { user, credential } }, signInApple);
        // IdP data available using getAdditionalUserInfo(result)
        return result;
        // ...
      })
      .catch(_ => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error);
        return null;
        // ...
      });
  };

  const logOut = (onLogout?: () => void) => {
    signOut(auth).then(() => {
      if (onLogout) onLogout();
      navigate('/', { replace: true });
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      Logger({ objs: { user } }, auth.onAuthStateChanged);
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const values = useMemo(
    () => ({
      auth,
      user,
      signInGoogle,
      signInApple,
      logOut,
    }),
    [user],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
