import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DemmiButtonBaseProps } from './buttons.helper';
interface DemmiIconButtonProps extends DemmiButtonBaseProps {
  other?: string;
}

const CSSBlock = 'demmi-ui-icon-button';
export const faStyles = ['fa-solid', 'fa-regular', 'fa-light', 'fa-thin', 'fa-duotone'];
const DemmiIconButton = React.forwardRef<HTMLButtonElement, DemmiIconButtonProps>(
  ({ faIcon, icon, className, onClick, size, tabIndex, disabled, invert, styles }: DemmiIconButtonProps, ref) => {
    return (
      <button
        className={`${CSSBlock} ${size ? `${CSSBlock}--${size}` : ''} ${invert ? `${CSSBlock}--invert` : ''} ${
          className || ''
        }`}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        style={styles}
        {...(tabIndex ? { tabIndex: tabIndex } : {})}>
        {icon ? (
          <FontAwesomeIcon icon={icon} />
        ) : (
          faIcon && <i className={faStyles.find(item => faIcon.includes(item)) ? faIcon : `fa-solid ${faIcon}`}></i>
        )}
      </button>
    );
  },
);

DemmiIconButton.displayName = 'DemmiIconButton';
export default DemmiIconButton;
